import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeOfBoolean',
})
export class TypeOfBooleanPipe implements PipeTransform {
  transform(value: any): boolean {
    return typeof value === 'boolean';
  }
}

@Pipe({
  name: 'typeOfArray',
})
export class TypeOfArrayPipe implements PipeTransform {
  transform(value: any): boolean {
    return Array.isArray(value);
  }
}

@Pipe({
  name: 'typeOfString',
})
export class TypeOfStringPipe implements PipeTransform {
  transform(value: any): boolean {
    return typeof value === 'string';
  }
}

@Pipe({
  name: 'typeOfUndefined',
})
export class TypeOfUndefinedPipe implements PipeTransform {
  transform(value: any): boolean {
    return typeof value === 'undefined';
  }
}
