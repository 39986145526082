import {
  OrderformFactor,
  OrderGrade,
  OrderNetwork,
  OrderRegion,
  OrderShippingProvider,
  OrderShippingSpeed,
  OrderSimType,
} from 'src/pages/manage-subscriptions/order-sims/order-sims.enums';
import { Serializable } from './serializable.class';
import { MyMetadataFile } from './my-file.class';

export class SimOrder extends Serializable {
  _id: string;
  accountId: string;
  accountName: string;
  contact: ISimOrderContact;
  orderDetail: ISimOrderOrderDetail[];
  config: ISimOrderConfig;
  delivery: ISimOrderDelivery;
  detail?: ISimOrderDetail;
  trackingInformation?: ISimOrderTrackingInformation[];
  attachmentRefIds?: ISimOrderAttachment[];
  useOfMSISDN: boolean;
  useOfCatM: boolean;
  catMCertified: boolean;

  public getResourceName(): string {
    return 'sim-orders';
  }
}
export interface ISimOrderAttachment {
  id: string;
  accountId: string;
}

export interface IOrderTrackingInformation {
  trackingNumber: string;
  trackingLink: string;
  courier: string;
  courierSelect: string;
}

export interface IOrderSimModel {
  networkService: OrderNetwork;
  multiImsiCarriers: string;
  formFactor: OrderformFactor;
  grade: OrderGrade;
  volume: number;
  simType: OrderSimType;
}

export interface IOrderModel {
  status: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  region: OrderRegion;
  sims: IOrderSimModel[];
  useOfCatM: boolean;
  catMCertified: boolean;
  sameAsContact: boolean;
  deliveryFirstName: string;
  deliveryLastName: string;
  shippingSpeed: OrderShippingSpeed;
  shippingAccount: boolean;
  shippingAccountProvider: OrderShippingProvider;
  shippingAccountNumber: string;
  billingZip: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  comments: string;
  areNotesVisible: boolean;
  notes: string;
  useOfMSISDN: boolean;
  trackingInformation: IOrderTrackingInformation[];
  attachments: MyMetadataFile[];
}

export interface ISimOrderConfig {
  areNotesVisible: boolean;
  sameAsContact: boolean;
  notes: string;
  useOfCatM: boolean;
  catMCertified: boolean;
}

export interface ISimOrderContact {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  region: string;
}

export interface ISimOrderDelivery {
  deliveryFirstName: string;
  deliveryLastName: string;
  shippingSpeed: string;
  shippingProvider: string;
  hasShippingAccount: boolean;
  shippingAccount: string;
  shippingZip: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  comments: string;
}

export interface ISimOrderTrackingInformation {
  trackingNumber: string;
  trackingLink: string;
  courrier: string;
}

export interface ISimOrderDetail {
  confirmationNumber?: string;
  date?: string;
  orderClosedDate?: string;
  resellerId?: string;
  resellerName?: string;
  status: string;
  userId?: string;
  userName?: string;
}

export enum OrderStatus {
  NEW = 'new',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  HOLD = 'hold',
  CANCELLED = 'cancelled',
}

export interface ISimOrderOrderDetail {
  simType: string;
  network: string;
  multiImsiCarriers: string;
  formFactor: string;
  grade: string;
  volume: number;
}

export enum Courier {
  DHL = 'DHL',
  FedEx = 'FedEx',
  UPS = 'UPS',
  USPS = 'USPS',
  Other = 'Other',
}
