import momentTimeZone from 'moment-timezone';
import moment, { Moment } from 'moment';

export function monthlyDateRange(substract: number, add: number) {
  return {
    start: moment().startOf('month').subtract(substract, 'months'),
    end: moment().endOf('month').add(add, 'months'),
  };
}

export function formatToUTC(_moment: Moment): string {
  return _moment.utc(true).format();
}

export function formatDateToStr(
  date: Date | string,
  timezone?: string,
  format?: string
): string {
  return timezone
    ? momentTimeZone.tz(date, timezone).format(format)
    : moment(date).format(format);
}

export function isValidMoment(dateTimeStr: string, format: string) {
  return moment(dateTimeStr, format).isValid();
}

export function toDateTimeZone(
  dateTime: Date | string,
  timeZone: string,
  format?: string
) {
  return momentTimeZone.tz(moment(dateTime).format(format), timeZone).toDate();
}

export function toDate(date: string) {
  return moment(date).toDate();
}

