import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { FileSizePipe } from './file-size/file-size.pipe';
import { FileSizeMbPipe } from './file-size-mb/file-size-mb.pipe';
import { FileUrlPipe } from './file-url/file-url.pipe';
import { Nl2BrPipe } from './nl2br/nl2br.pipe';
import { PermissionPipe } from './permission/permission.pipe';
import { RemainingPipe } from './remaining/remaining.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { TimezonePipe } from './timezone/timezone.pipe';
import { PodCurrencyPipe } from './currency/pod-currency.pipe';
import { FromNowPipe } from './from-now/from-now.pipe';
import { PodDecimalPipe } from './pod-decimal/pod-decimal.pipe';
import { AsyncFuncPipe } from './async-func/async-func.pipe';
import {
  TypeOfArrayPipe,
  TypeOfBooleanPipe,
  TypeOfStringPipe,
  TypeOfUndefinedPipe,
} from './type-of/type-of.pipe';

const pipes = [
  PodCurrencyPipe,
  PodDecimalPipe,
  FileSizePipe,
  FileSizeMbPipe,
  FileUrlPipe,
  Nl2BrPipe,
  PermissionPipe,
  RemainingPipe,
  SafeHtmlPipe,
  TimezonePipe,
  FromNowPipe,
  AsyncFuncPipe,
  TypeOfBooleanPipe,
  TypeOfArrayPipe,
  TypeOfStringPipe,
  TypeOfUndefinedPipe,
];

@NgModule({
  declarations: [
    PodCurrencyPipe,
    PodDecimalPipe,
    FileSizePipe,
    FileSizeMbPipe,
    FileUrlPipe,
    Nl2BrPipe,
    PermissionPipe,
    RemainingPipe,
    SafeHtmlPipe,
    TimezonePipe,
    FromNowPipe,
    AsyncFuncPipe,
    TypeOfBooleanPipe,
    TypeOfArrayPipe,
    TypeOfStringPipe,
    TypeOfUndefinedPipe,
  ],
  imports: [CommonModule],
  exports: [
    PodCurrencyPipe,
    PodDecimalPipe,
    FileSizePipe,
    FileSizeMbPipe,
    FileUrlPipe,
    Nl2BrPipe,
    PermissionPipe,
    RemainingPipe,
    SafeHtmlPipe,
    TimezonePipe,
    FromNowPipe,
    AsyncFuncPipe,
    TypeOfBooleanPipe,
    TypeOfArrayPipe,
    TypeOfStringPipe,
    TypeOfUndefinedPipe,
  ],
  providers: [
    PodCurrencyPipe,
    PodDecimalPipe,
    FileSizePipe,
    FileSizeMbPipe,
    FileUrlPipe,
    Nl2BrPipe,
    PermissionPipe,
    RemainingPipe,
    SafeHtmlPipe,
    TimezonePipe,
    CurrencyPipe,
    DecimalPipe,
    FromNowPipe,
    AsyncFuncPipe,
    TypeOfBooleanPipe,
    TypeOfArrayPipe,
    TypeOfStringPipe,
    TypeOfUndefinedPipe,
  ],
})
export class PipesModule {}
