import { Directive, Input, forwardRef } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  Validator,
  ValidatorFn,
} from '@angular/forms';
import momentTimeZone from 'moment-timezone';
import { DateTimeZone } from 'src/pages/incidents/incidents.utils';

export function checkMinDate(
  minDateTime: string,
  minTimeZone: string,
  currentTimeZone: string
): ValidatorFn {
  return (c: AbstractControl): { [key: string]: any } | null => {
    if (!currentTimeZone) return null;
    const dateMoment = momentTimeZone.tz(minDateTime, minTimeZone);
    const endTimeMoment = momentTimeZone.tz(c.value, currentTimeZone);
    return dateMoment.isAfter(endTimeMoment) ? { isBefore: true } : null;
  };
}

@Directive({
  selector: '[minDateTimeZone]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MinDateTimeValidatorDirective),
      multi: true,
    },
  ],
})
export class MinDateTimeValidatorDirective implements Validator {
  @Input() minDateTimeZone: Partial<DateTimeZone>;
  constructor() {}
  validate(control: AbstractControl): { [key: string]: any } {
    if (control.value) {
      const { dateTime, timezone } = control.value;
      return momentTimeZone
        .tz(this.minDateTimeZone.dateTime, this.minDateTimeZone.timezone)
        .isAfter(momentTimeZone.tz(dateTime, timezone))
        ? {
            custom: [
              {
                key: 'validation.endDateInvalid',
                params: {},
              },
            ],
          }
        : null;
    } else {
      return null;
    }
  }
}
