import { Account } from 'pod-ng-core';
import { Serializable } from './serializable.class';
import { ZoneScheme } from './zone-scheme.class';

export interface IncidentsWizardSchema {
  properties: any;
  zoneScheme: Array<ZoneScheme>;
  required?: Array<string>;
}

export interface IncidentMail {
  affectedAccounts: string[];
  subject: string;
  body: string;
  recipients: string[];
  sentAt: Date;
}

export interface IncidentComponent {
  name: string;
  index: number;
  type: string;
  statusName?: string;
}

export class Incident extends Serializable {
  public static MAX_CONTRACT_LENGTH = 6;
  _id: string;
  title: string;
  startTime: Date;
  startTimeZone: string;
  endTime?: Date;
  endTimeZone?: string;
  lastUpdatedTime: Date;
  location: string[];
  status: string;
  criticality: string;
  template: string;
  type: string;
  componentAffected: string[];
  servicesAffected: string[];
  statusPageMessage: string;
  description: string;
  sendEmail: boolean;
  email: Partial<IncidentMail>;
  emailHistory: IncidentMail[];

  public getResourceName(): string {
    return 'incidents';
  }

  public getExportPath(): string {
    return this.getBulkPath();
  }

  public init(json: any = {}) {
    super.init(json);
  }
}

export type AffectedAccount =
  | Pick<Account, 'name' | 'defaultEmail' | 'id'> & { _id: string };
