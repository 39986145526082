import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextEditorRequiredDirective } from './text-editor-required.directive';

@NgModule({
  declarations: [TextEditorRequiredDirective],
  imports: [CommonModule],
  exports: [TextEditorRequiredDirective],
})
export class TextEditorValidatorDirectiveModule {}
