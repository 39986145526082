import { Routes } from '@angular/router';

import { CampaignType } from 'src/models/campaign.class';
import { MainComponent } from 'src/pages/main/main.component';
import { StartComponent } from 'src/pages/main/start.component';
import { AuthGuard } from 'src/services/auth.guard';
import { PasswordChangeGuard } from 'src/services/expired-password.guard';
import { NotPasswordChangeGuard } from 'src/services/not-expired-password.guard';

export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, PasswordChangeGuard],
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: '/start',
        pathMatch: 'full',
      },
      {
        path: 'start',
        component: StartComponent,
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('../pages/dashboard/dashboard.module').then(
            m => m.DashboardModule
          ),
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('../pages/customers/customers.module').then(
            m => m.CustomersModule
          ),
      },
      {
        path: 'manage-subscriptions',
        loadChildren: () =>
          import(
            '../pages/manage-subscriptions/manage-subscriptions.module'
          ).then(m => m.ManageSubscriptionsModule),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('../pages/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'devices',
        loadChildren: () =>
          import('../pages/devices/devices.module').then(m => m.DevicesModule),
      },
      {
        path: 'campaigns',
        loadChildren: () =>
          import('../pages/campaigns/campaigns.module').then(
            m => m.CampaignsModule
          ),
        data: {
          permission: [
            ...Object.values(CampaignType).map(
              type => 'list-campaigns-' + type
            ),
            ...Object.values(CampaignType).map(
              type => 'create-campaign-' + type
            ),
          ],
          redirectTo: '',
        },
      },
      {
        path: 'billing',
        loadChildren: () =>
          import('../pages/billing/billing.module').then(m => m.BillingModule),
      },
      {
        path: 'gd-billing',
        loadChildren: () =>
          import('../pages/gd-billing/gd-billing.module').then(
            m => m.GdBillingModule
          ),
      },
      {
        path: 'incidents',
        loadChildren: () =>
          import('../pages/incidents/incidents.module').then(
            m => m.IncidentsModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('../pages/settings/settings.module').then(
            m => m.SettingsModule
          ),
      },
      {
        path: 'support',
        loadChildren: () =>
          import('../pages/support/support.module').then(m => m.SupportModule),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('../pages/notifications/notifications.module').then(
            m => m.NotificationsModule
          ),
      },
    ],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('../pages/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('../pages/maintenance/maintenance.module').then(
        m => m.MaintenanceModule
      ),
  },
  {
    canActivate: [AuthGuard, NotPasswordChangeGuard],
    path: 'expired-password',
    loadChildren: () =>
      import(
        '../pages/force-password-change/force-password-change.module'
      ).then(m => m.ForcePasswordChangeModule),
  },
  // otherwise redirect to home
  {
    path: '**',
    redirectTo: '',
  },
];
