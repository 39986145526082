import { Serializable } from './serializable.class';
import { Cycle } from './bundle-report.iface';

export enum CommercialReferenceFrecuencyUnit {
  MONTH = 'month',
  YEAR = 'year',
}

export interface CommercialReferenceFrecuency {
  cycle: number;
  cycleUnit?: CommercialReferenceFrecuencyUnit;
}

export enum CommercialReferenceValidStatus {
  PENDING = 'pending',
  RUNNING = 'running',
  COMPLETED = 'completed',
}
export enum CommercialReferenceInvalidStatus {
  DELETED = 'deleted',
}

export type CommercialReferenceAllStatus = CommercialReferenceValidStatus &
  CommercialReferenceInvalidStatus;

export enum CommercialReferenceCostType {
  VOLUME = 'volume',
  TIER = 'tier',
  STAIRSTEP = 'stairStep',
  TIERACCUMULATION = 'tierAccumulation',
}

export interface CommercialReferenceCostSchemeCostRange {
  since: number;
  cost: Record<CommercialReferenceGdBillingCostKeys, number>;
}

export interface CommercialReferenceCostSchemeDiscountRange {
  since: number;
  discount: number;
}

export enum CommercialReferenceGdBillingCostKeys {
  DOWNLOAD = 'downloadCost',
}

export enum CommercialReferenceGdBillingBillableItemType {
  RECURRING = 'recurring',
  USAGE = 'usage',
  JOINTACCUMULATION = 'jointAccumulation',
}
export enum LineItemAdditionalInfo {
  PURCHASEORDER = 'purchaseOrder',
  PROFILEGROUP = 'profileGroup',
  LINEITEM = 'lineItem',
  NULL = 'null',
}

export interface CommercialReferenceCostSchemeGdBilling {
  autoBilling: boolean;
  contractId: string;
  lineItemAdditionalInfo: LineItemAdditionalInfo;
  contractStartDate: string;
  contractExpiryDate: string;
  purchaseOrders: GdBillingPurchaseOrder[];
  customerNumber: string;
  salesData: CommercialReferenceSalesData;
  jointAccumulators: JointAccumulator[];
}

export interface CommercialReferenceSalesData {
  salesOrganization: string;
  distributionChannel: string;
  division: string;
  incoterms1: string;
  incoterms2: string;
  partnerRoles: Record<string, string>;
  currency: string;
  contactPerson: string;
}

export interface JointAccumulator {
  name: string;
  costType: CommercialReferenceCostType;
  costRanges: CommercialReferenceCostSchemeCostRange[];
  startDate?: string;
  resetDate?: string;
  accumulated?: number;
  preaccumulated?: number;
  accumulationPeriod?: CommercialReferenceFrecuency;
  historyPerPeriod?: HistoryPerPeriod[];
}

export interface GdPOMaterial {
  materialNumber: string;
  materialDescription: string;
  wbsElement: string;
  unitOfMeasure: string;
  materialGroup1: string;
  materialGroup2: string;
  materialGroup3: string;
  materialGroup4: string;
  sapPriceUnit: number;
  costType: CommercialReferenceCostType;
  costRanges: CommercialReferenceCostSchemeCostRange[];
  billableItemType: CommercialReferenceGdBillingBillableItemType;
  profileType: {
    group: string;
    values: string[];
  };
  unitPrice: number;
  startDate: string;
  resetDate: string;
  accumulated: number;
  preaccumulated: number;
  accumulationPeriod: CommercialReferenceFrecuency;
  historyPerPeriod: HistoryPerPeriod[];
  jointAccumulator?: string;
}

export interface HistoryPerPeriod {
  startDate: string;
  resetDate: string;
  accumulated: number;
}

export interface GdBillingPurchaseOrder {
  purchaseOrderHandlingType: GdBillingPurchaseOrderHandlingType;
  purchaseOrderNumber: string;
  startDate: string;
  endDate: string;
  quantity: number;
  remaining: number;
  used: boolean;
  blockedQuantity: number;
  materials: GdPOMaterial[];
}

export enum GdBillingPurchaseOrderHandlingType {
  BLANKET = 'blanket',
  ARREARS = 'arrears',
  ADVANCE = 'advance',
}

export class CommercialReference extends Serializable {
  public _id: string;
  public name: string;
  public description: string;
  public accountId: string;
  public accountTransferId: string;
  public accountTransferName: string;
  public accountCurrency: string;
  public frecuency: CommercialReferenceFrecuency;
  public contractLength: number;
  public firstPaymentCycle: Cycle;
  public costScheme: CommercialReferenceCostSchemeGdBilling;
  public status: CommercialReferenceAllStatus;
  public productId?: string;
  public lastPaymentCycle: Cycle;
  public created: string;
  public modified: string;

  public static getFrecuencyCycles = (
    cycle: number,
    cycleUnit: CommercialReferenceFrecuencyUnit
  ) => {
    let mult: number;
    switch (cycleUnit) {
      case CommercialReferenceFrecuencyUnit.MONTH:
        mult = 1;
        break;
      case CommercialReferenceFrecuencyUnit.YEAR:
        mult = 12;
        break;
      default:
        throw `${cycleUnit} is not a valid cycleUnit`;
    }

    return cycle * mult;
  };

  public static getCyclesDiff = (cycleA: Cycle, cycleB?: Cycle): number => {
    cycleB = cycleB || {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    };

    return (cycleA.year - cycleB.year) * 12 + cycleA.month - cycleB.month;
  };

  public getResourceName(): string {
    return 'commercial-references';
  }
}

export interface CommercialReferenceHistory {
  _id: string;
  accountId: string;
  additionalProductId: string;
  cycle: Cycle;
  quantity: number;
  unitAmount: number;
  totalCostWithoutDiscount: number;
  discountAmount: number;
  total: number;
  description: string;
}
