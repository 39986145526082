import {
  Component,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomizableTableCustomizeModal } from './customizable-table-customize.modal';
import { Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ExportModal, IExportModalResult } from './export-modal/export.modal';
import { Serializable } from '../../models';
import { ApiList, ApiService, SessionService } from '../../services/index';
import { environment } from 'src/environments/environment';

export enum CtSelectedMode {
  VISIBLE = 'visible',
  ALL = 'all',
}

export interface ITableField<T extends Serializable = Serializable> {
  label: string;
  content: (item: T) => string;
  csv: boolean;
  order?: string;
  reverse?: boolean;
  link?: (item: T) => any;
  queryParams?: (item: T) => Record<string, any>;
  click?: (event: Event, item: T) => void;
  classes?: string;
  permission?: boolean;
  state?: (item: T) => any;
}

export interface IRowOperation<T extends Serializable = Serializable> {
  icon: string;
  hasPermission?: boolean;
  label: string;
  action: (item: T) => void;
  disabled?: (item: T) => boolean;
  hide?: (item: T) => boolean;
  hideAsync?: any;
  id: string;
}

export interface IBulkAction<T extends Serializable = Serializable> {
  icon: string;
  label: string;
  action: (
    selectedItems?: Record<string, T>,
    selectedMode?: CtSelectedMode,
    searchParams?: ISearchParams
  ) => void;
  enabled?: (
    selectedItems: Record<string, T>,
    selectedMode?: CtSelectedMode,
    searchParams?: ISearchParams
  ) => boolean;
  hasPermission: boolean;
  showLabelOnButton?: boolean;
  id: string;
}

export interface IBulkActionGroup<T extends Serializable = Serializable> {
  label: string;
  visible: boolean;
  row?: number;
  actions: IBulkAction<T>[];
  id: string;
}

export interface ISearchParams {
  get?: Record<string, any>;
  bulk?: Record<string, any>;
  default?: Record<string, any>;
}

export interface IPageInfo {
  page: number;
  size: number;
}

export interface ISelectionChangeEvent<T extends Serializable = Serializable> {
  selectedMode: CtSelectedMode;
  selectedItems: Record<string, T>;
  totalSelected: number;
}

export interface IOrderChangeEvent {
  field: string;
  order: 'asc' | 'desc';
}

@Component({
  selector: 'pod-customizable-table',
  templateUrl: './customizable-table.component.html',
  styleUrls: ['customizable-table.component.scss'],
})
export class CustomizableTableComponent<T extends Serializable = Serializable>
  implements OnInit, OnChanges
{
  private exportDataActionGroup: IBulkActionGroup = {
    label: 'label.exportData',
    visible: true,
    row: 1,
    id: 'downloadCSVBulk',
    actions: [
      {
        icon: 'download',
        label: 'label.exportData',
        hasPermission: true,
        action: () => this.downloadCSV(),
        id: 'downloadCSV',
      },
    ],
  };

  @Input() itemClass?: any;
  @Input() fields: Record<string, ITableField<T>>;
  @Input() searchParams: ISearchParams = {};
  @Input() totalItems?: number;
  @Input() params?: Record<string, any>;
  @Input() staticItems?: ApiList<T>;
  @Input() loading = false;

  @Input() routerTarget?: string;
  @Input() routerTargetField?: string;
  @Input() selectOnClick = false;
  @Input() defaultSelectedFields?: string[];
  @Input() setupTableName?: string;
  @Input() operations?: IRowOperation[];

  @Input() rowClass?: (item: T) => string;
  @Input() getAction?: string;
  @Input() bulkPath?: string;
  @Input() bulkActions?: IBulkActionGroup[];
  @Input() actions?: IBulkActionGroup[];
  @Input() exportData = true;

  @Input() itemsPerPage: number[] = [10, 20, 100];
  @Input() showSelected = false;
  @Input() selectionModes = false;
  @Input() initialSelectionMode: CtSelectedMode = null;

  @Input() showSearch = true;
  @Input() isCustomizable = true;
  @Input() advancedSearchCount?: number;
  @Input() maxFiltersInTable: number = environment.maxFiltersInTable || 100000;

  public maxFiltersForced: boolean = false;

  private _pageInfo: IPageInfo;
  @Input() get pageInfo(): IPageInfo {
    // Setting default values
    this._pageInfo = this._pageInfo || { page: undefined, size: undefined };
    this._pageInfo.page = this._pageInfo.page || 1;
    this._pageInfo.size = this._pageInfo.size || this.itemsPerPage[0] || 10;

    return this._pageInfo;
  }
  set pageInfo(pi: IPageInfo) {
    this._pageInfo = pi;
  }
  @Output() pageInfoChange = new EventEmitter<IPageInfo>();

  @Output() listChange = new EventEmitter<ApiList<T>>();
  @Output() orderChange = new EventEmitter<IOrderChangeEvent>();
  @Output() loadingChange = new EventEmitter<boolean>(true);
  @Output() advancedSearchSubmit = new EventEmitter<void>(true);
  @Output() advancedSearchReset = new EventEmitter<void>(true);
  @Output() selectedFieldsChange = new EventEmitter<string[]>(true);
  @Output() onRowClick = new EventEmitter<T>(true);
  @Output() onSelectionChange = new EventEmitter<ISelectionChangeEvent<T>>();

  public readonly CtSelectedMode = CtSelectedMode;

  public bulkActionRows: IBulkActionGroup[][] = [];
  public actionRows: IBulkActionGroup[][] = [];

  public selectedFields: string[];
  public items: ApiList<T>;

  public selectedItems?: Record<string, T> = {};
  public currentSort: {
    field: string;
    order: 'asc' | 'desc';
  };
  public selectionModeDropdownOpened = false;
  public selectedMode: CtSelectedMode = null;

  constructor(
    private ngbModal: NgbModal,
    private apiService: ApiService,
    private session: SessionService
  ) {}

  ngOnInit() {
    // set pageSize from itemsPerPage
    this.pageInfo.size = this.pageInfo.size || this.itemsPerPage[0];

    // get selectedFields from user setup
    if (
      this.setupTableName &&
      this.session.getTableColumns(this.setupTableName)
    ) {
      this.selectedFields = this.session.getTableColumns(this.setupTableName);
    }
    // Set all by default
    this.selectedFields =
      this.selectedFields ||
      (this.defaultSelectedFields
        ? [...this.defaultSelectedFields]
        : Object.keys(this.fields));
    // Check if field exists and have permissions
    this.selectedFields = this.selectedFields.filter(
      key => this.fields[key] && this.fields[key].permission !== false
    );

    // get sort and order from user setup (checking if in selectedFields)
    if (this.setupTableName && this.session.getTableSort(this.setupTableName)) {
      const setupSort = this.session.getTableSort(this.setupTableName);
      if (setupSort && this.selectedFields.indexOf(setupSort.field) >= 0) {
        this.currentSort = setupSort;
      }
    }
    // If not currentSort, set as the first field with order
    if (!this.currentSort) {
      const firstOrderField = this.selectedFields.find(
        key => !!this.fields[key].order
      );
      if (firstOrderField) {
        this.currentSort = {
          field: firstOrderField,
          order: this.fields[firstOrderField].reverse ? 'desc' : 'asc',
        };
      }
    }

    this.selectedMode = this.initialSelectionMode;
    this.getItems();
  }

  ngOnChanges(changes: SimpleChanges) {
    const bulkActionsChange = changes.bulkActions;
    if (bulkActionsChange) {
      this.bulkActionRows = this.bulkActionsGroupsInRows(this.bulkActions);
    }

    const actionsChange = changes.actions;
    const exportDataChange = changes.exportData;
    if (actionsChange || exportDataChange) {
      this.actionRows = this.bulkActionsGroupsInRows(
        (this.actions || []).concat(
          this.exportData ? this.exportDataActionGroup : []
        )
      );
    }

    const paramsChange = changes.params;
    if (paramsChange && !paramsChange.firstChange) {
      this.reload();
    }

    const searchParamsChange = changes.searchParams;
    if (searchParamsChange && !searchParamsChange.firstChange) {
      this.reload();
    }

    const staticItemsChange = changes.staticItems;
    if (staticItemsChange && !staticItemsChange.firstChange) {
      this.reload();
    }
  }

  private bulkActionsGroupsInRows(groups: IBulkActionGroup[]) {
    return (groups || [])
      .reduce<IBulkActionGroup[][]>((prev, group) => {
        const row = group.row || 1;
        prev[row] = prev[row] || [];
        prev[row].push(group);
        return prev;
      }, [])
      .filter(row => row && row.length);
  }

  private setLoading(loading: boolean) {
    this.loading = loading;
    this.loadingChange.emit(this.loading);
  }

  private getList(): Observable<ApiList<T>> {
    if (this.staticItems) {
      return of(this.staticItems);
    } else if (this.itemClass) {
      this.setLoading(true);
      const params: Record<string, any> = {
        limit: this.pageInfo.size,
        page: this.pageInfo.page,
      };

      if (
        this.currentSort &&
        this.fields[this.currentSort.field] &&
        this.fields[this.currentSort.field].order
      ) {
        params.sort = this.fields[this.currentSort.field].order;
      }

      if (this.currentSort && this.currentSort.order) {
        params.order = this.currentSort.order;
      }

      let getAction: Observable<ApiList<T>>;
      if (!!this.bulkPath) {
        getAction = this.apiService.getBulkList(
          this.itemClass,
          this.bulkPath,
          { ...params, ...this.searchParams.get },
          { ...this.searchParams.bulk, ...(this.params || {}) }
        );
      } else {
        getAction = this.apiService.getList(
          this.itemClass,
          { ...params, ...this.searchParams.get, ...(this.params || {}) },
          this.getAction
        );
      }
      return getAction.pipe(
        finalize(() => {
          this.setLoading(false);
        })
      );
    }
  }

  public getItems() {
    this.getList().subscribe(list => {
      this.items = list;
      this.listChange.emit(this.items);

      if (Object.keys(this.selectedItems).length) {
        if (this.selectedMode === CtSelectedMode.VISIBLE) {
          this.selectVisible();
        } else {
          this.selectedItems = {};
        }
      }
      this.emitSelectionChange();
    });
  }

  public reload() {
    this.pageInfo.page = 1;
    this.deselectAll();
    this.getItems();
  }

  public customize() {
    const modalRef = this.ngbModal.open(CustomizableTableCustomizeModal);
    modalRef.componentInstance.fields = this.fields;
    modalRef.componentInstance.selectedFields = this.selectedFields;
    modalRef.componentInstance.defaultSelectedFields =
      this.defaultSelectedFields;

    modalRef.result
      .then(selectedFields => {
        this.selectedFields = selectedFields;
        // Save the customization
        if (this.setupTableName) {
          this.session.setTableColumns(this.setupTableName, selectedFields);
        }
        this.selectedFieldsChange.emit(this.selectedFields);
      })
      .catch(() => {});
  }

  public openAdvancedSearch(content) {
    this.ngbModal
      .open(content, { size: 'lg' })
      .result.then((reset: boolean = false) => {
        if (reset) {
          this.advancedSearchReset.emit();
        } else {
          this.advancedSearchSubmit.emit();
        }
      })
      .catch(() => {});
  }

  public setOrder(fieldName: string) {
    if (this.fields[fieldName] && this.fields[fieldName].order) {
      if (this.currentSort && this.currentSort.field === fieldName) {
        this.currentSort.order =
          this.currentSort.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.currentSort = {
          field: fieldName,
          order: this.fields[fieldName].reverse ? 'desc' : 'asc',
        };
      }
      this.orderChange.emit(this.currentSort);
      // Save the customization
      if (this.setupTableName && this.session.hasPermission('customize-me')) {
        this.session.setTableSort(this.setupTableName, this.currentSort);
      }
      this.reload();
    }
  }

  private emitSelectionChange() {
    const { selectedMode, selectedItems } = this;
    this.onSelectionChange.emit({
      selectedMode,
      selectedItems,
      totalSelected:
        selectedMode === CtSelectedMode.ALL
          ? this.items.$totalCount
          : Object.keys(selectedItems).length,
    });
  }

  public selectVisible() {
    this.selectedMode = CtSelectedMode.VISIBLE;
    this.selectedItems = {};
    this.items.forEach(item => {
      this.selectedItems[item.getId()] = item;
    });

    this.emitSelectionChange();
  }

  public selectAll() {
    this.selectedMode = CtSelectedMode.ALL;
    this.selectedItems = {};

    this.emitSelectionChange();
  }

  public deselectAll() {
    this.selectedMode = null;
    this.selectedItems = {};

    this.emitSelectionChange();
  }

  public switchSelectVisible() {
    if (this.selectedMode === 'visible') {
      this.deselectAll();
    } else {
      this.selectVisible();
    }
  }

  public selectItem(event: Event, item: T) {
    event.stopPropagation();
    if (!this.selectedItems[item.getId()]) {
      this.selectedItems[item.getId()] = item;
    } else {
      delete this.selectedItems[item.getId()];
      this.selectedMode = null;
    }
    this.emitSelectionChange();
  }

  public getSelectedItems() {
    return this.selectedItems;
  }

  public getTotalSelected() {
    // return Object.keys(this.selectedItems).length;
    return this.selectedMode === 'all'
      ? this.items.$totalCount
      : Object.keys(this.selectedItems).length;
  }

  public downloadCSV() {
    // Save the customization
    if (this.setupTableName && this.session.hasPermission('customize-me')) {
      this.session.setTableColumns(this.setupTableName, this.selectedFields);
    }

    const params = {
      limit: 0,
      sort:
        this.currentSort &&
        this.fields[this.currentSort.field] &&
        this.fields[this.currentSort.field].order
          ? this.fields[this.currentSort.field].order
          : undefined,
      order: this.currentSort ? this.currentSort.order : undefined,
    };

    const modalRef = this.ngbModal.open(ExportModal);

    modalRef.componentInstance.total = this.countAmount(this.totalItems || 0);
    modalRef.componentInstance.filtered = this.countAmount(
      this.items.$totalCount || 0
    );
    modalRef.componentInstance.selected =
      this.selectedMode === 'all'
        ? 0
        : Object.keys(this.selectedItems).length || 0;

    modalRef.componentInstance.selectedColumns = this.selectedFields.filter(
      key => this.fields[key].csv && this.fields[key].permission !== false
    );
    modalRef.componentInstance.allColumns = Object.keys(this.fields).filter(
      key => this.fields[key].csv && this.fields[key].permission !== false
    );

    modalRef.result
      .then((options: IExportModalResult) => {
        let extraParams: any = {};
        const extraParamKey = this.itemClass.prototype.getIdKey();

        switch (options.recordsToExport) {
          case 'filtered':
            extraParams = {
              ...params,
              ...this.searchParams.get,
              ...this.searchParams.bulk,
              ...(this.params || {}),
            };
            break;
          case 'selected':
            extraParams = {
              ...params,
              ...(this.params || {}),
              [extraParamKey]: Object.keys(this.selectedItems).join(','),
            };
            break;
          default:
            extraParams = { ...params, ...(this.searchParams.default || {}) };
            break;
        }

        extraParams.columns = options.columnsToExport;
        if (this.setupTableName) {
          extraParams.setupTable = this.setupTableName;
        }

        if (
          this.itemClass.prototype.getBulkPath() ===
          this.itemClass.prototype.getExportPath()
        ) {
          this.apiService.postAsForm(
            this.apiService.getListCsvUrl(this.itemClass),
            extraParams
          );
        } else {
          window.open(
            this.apiService.getListCsvUrl(this.itemClass, extraParams)
          );
        }
      })
      .catch(() => {});
  }

  public setPageSize(num) {
    this.pageInfo.size = parseInt(num, 10);
    this.onPageInfoChange();
  }

  public onPageInfoChange() {
    this.pageInfoChange.emit({
      page: this.pageInfo.page,
      size: this.pageInfo.size,
    });
    this.getItems();
  }

  public executeBulkAction(action: IBulkAction) {
    action.action(this.selectedItems, this.selectedMode, {
      get: { ...this.searchParams.get, ...(this.params || {}) },
      bulk: { ...this.searchParams.bulk },
    });
  }

  public handleRowClick(event: Event, item: T) {
    event.stopPropagation();
    if (this.selectOnClick) {
      this.selectItem(event, item);
    }

    this.onRowClick.emit(item);
  }

  public forceCount() {
    this.params.maxCount = 9e6; // High number to make sure that it is higher than the max on DB
    this.getItems();
    this.apiService
      .getList(this.itemClass, this.params, this.getAction)
      .subscribe(result => {
        this.maxFiltersForced = true;
        this.totalItems = result.$totalCount;
      });
  }

  public countAmount(amount: number) {
    return !this.maxFiltersForced && amount > this.maxFiltersInTable
      ? this.maxFiltersInTable + '+'
      : amount || ' ';
  }
}
