/**
 * Created by angel on 4/07/17.
 */
import { Serializable } from './serializable.class';

export class MyFile extends Serializable {
  filename: string;
  contentType: string;
  length: number;
  chunkSize: number;
  uploadDate: string;
  md5: string;

  public getResourceName(): string {
    return 'file';
  }
}

export type MyFileActionType = 'metadata';

export class MyMetadataFile extends MyFile {
  _id: string;
  accountId: string;
  public getResourceName(): string {
    return MyFile.prototype.getResourcePath();
  }

  public getId(): string {
    return this._id;
  }

  public getAction(): MyFileActionType {
    return 'metadata';
  }
}
